import { ApolloClient, InMemoryCache, HttpLink, split, NormalizedCacheObject, ApolloLink } from "@apollo/client/core";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import * as Sentry from "@sentry/vue";
import { ApolloClients } from "@vue/apollo-composable";
import { ApolloProvider, createApolloProvider } from "@vue/apollo-option";
import { initialize } from "launchdarkly-js-client-sdk";
import { IdToken } from "@auth0/auth0-vue";
import { App, createApp, watchEffect } from "vue";
import { createPinia } from "pinia";
import { auth0 } from "./auth0";
import { getRouters } from "./router";
import MomentumApp from "./App.vue";
import "./assets/css/index.css";
import store from "./store";
import { toggleSideBar } from "./utils/mobile";
import { initializeSegment } from "./utils/common-js";
import { logSentryErrors } from "./utils/log-sentry-errors";
//vue tippy
import VueTippy from "vue-tippy";
import "tippy.js/dist/tippy.css";

//fetch integrations object metadata
import { IntegrationObjectMetadataController } from "./integrations/objectMetadata";
import { FragmentDefinitionNode, OperationDefinitionNode } from "graphql/index";
import { isSupportConsole } from "./utils/is-support-console";

//initialize vue app
const app: App<Element> = createApp(MomentumApp);
const pinia = createPinia();

// variables
const env: Record<string, string> = import.meta.env;
const siteProfile: string = env.VITE_SITE_PROFILE;
const environmentUrl: string = isSupportConsole()
  ? // backwards compatible, so all engineers do not need to add env vars immediately
    env.VITE_ENVIRONMENT_URL_SUPPORT || env.VITE_ENVIRONMENT_URL
  : env.VITE_ENVIRONMENT_URL;

app.provide("isSupportConsole", siteProfile === "support-console");

addEventListener("resize", (): void => {
  toggleSideBar();
});

toggleSideBar();

app.use(
  VueTippy,
  // optional
  {
    directive: "tippy", // => v-tippy
    component: "tippy", // => <tippy/>
    componentSingleton: "tippy-singleton", // => <tippy-singleton/>,
    defaultProps: {
      placement: "auto-end",
      allowHTML: true,
    }, // => Global default options * see all props
  }
);

//Launch Darkly global mixin
app.mixin({
  methods: {
    getEnabledFeature(feature, authUser) {
      if (!feature || !authUser) {
        return;
      }
      if (authUser.isMomentumSupport) {
        // Turn on all feature flags for Momentum Support users
        this.featureToggles[feature] = true;
        return;
      }
      const ldUser = {
        key: authUser.userId,
        // Get feature-flags for impersonated user (profile.email), not authenticated user (authUser.email)
        email: authUser.email,
        custom: { organizationId: authUser.organizationId },
      };

      const ldClient = initialize(env.VITE_LAUNCH_DARKLY_CLIENT_ID, ldUser);
      ldClient.on("ready", () => {
        const showFeature = ldClient.variation(feature, false);
        if (showFeature) {
          this.featureToggles[feature] = true;
        }
      });
    },
  },
});

//when user is authenticated, initialize vue apollo configuration
watchEffect(async (): Promise<void> => {
  if (auth0?.isAuthenticated?.value && auth0?.idTokenClaims?.value) {
    await initApollo();
  } else if (!store.state.apolloPublic) {
    initApolloPublic();
  }
});

async function initApollo(options?: { refreshToken: string }): Promise<void> {
  const claims: IdToken = auth0.idTokenClaims.value;
  const token: string = claims.__raw;
  const headers = {
    authorization: `Bearer ${options?.refreshToken || token}`,
  };
  const graphCMSHeaders = {
    authorization: `Bearer ${env.VITE_GRAPHCMS_READ_TOKEN}`,
  };
  const cache = new InMemoryCache();
  const httpLink = new HttpLink({
    // You should use an absolute URL here
    uri: ({ operationName }): string => {
      return `${env.VITE_GRAPH_QL_URL_HTTP}?gqlOp=${operationName}`;
    },
    headers,
  });

  const graphCMSLink: HttpLink = new HttpLink({
    uri: env.VITE_GRAPHCMS_ENDPOINT,
    headers: graphCMSHeaders,
  });

  const wsLink: WebSocketLink = new WebSocketLink({
    uri: env.VITE_GRAPH_QL_URL_WS,
    options: {
      reconnect: true,
      connectionParams: {
        headers,
      },
    },
  });

  const link: ApolloLink = split(
    ({ query }) => {
      const definition: OperationDefinitionNode | FragmentDefinitionNode = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    httpLink
  );

  const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache,
    link,
  });

  const graphcms: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache,
    link: graphCMSLink,
  });

  const apolloProvider: ApolloProvider = createApolloProvider({
    defaultClient: apolloClient,
    clients: {
      graphcms,
    },
  });

  IntegrationObjectMetadataController.initializeApolloClient(apolloProvider.defaultClient);

  app.use(apolloProvider);
  app.provide("apollo", apolloProvider);
  //leaving this here until we migrate the useQueries off the other Vue 3 Composition APIs
  app.provide(ApolloClients, {
    default: apolloClient,
  });

  await store.dispatch("setApollo", apolloProvider);
  await store.dispatch("setAuthUser", claims);

  //Linking segment snippet to start tracking on different environments
  initializeSegment(env.VITE_SEGMENT_WRITE_KEY);
}

async function initApolloPublic(): Promise<void> {
  const cache = new InMemoryCache();
  const httpLink = new HttpLink({
    uri: ({ operationName }): string => {
      return `${env.VITE_GRAPH_QL_URL_HTTP}?gqlOp=${operationName}`;
    },
  });

  const wsLink: WebSocketLink = new WebSocketLink({
    uri: env.VITE_GRAPH_QL_URL_WS,
    options: {
      reconnect: true,
    },
  });

  const link: ApolloLink = split(
    ({ query }) => {
      const definition: OperationDefinitionNode | FragmentDefinitionNode = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    httpLink
  );

  const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache,
    link,
  });

  const apolloProvider: ApolloProvider = createApolloProvider({
    defaultClient: apolloClient,
  });

  IntegrationObjectMetadataController.initializeApolloClient(apolloProvider.defaultClient);

  app.use(apolloProvider);
  app.provide("apolloPublic", apolloProvider);
  await store.dispatch("setApolloPublic", apolloProvider);
  initializeSegment(env.VITE_SEGMENT_WRITE_KEY);
}

if (logSentryErrors()) {
  Sentry.init({
    app,
    dsn: "https://5777719dccac6acb233a39362a12564d@o4506719196348416.ingest.sentry.io/4506719221317632",
    beforeSend(event) {
      if (event.user) {
        event.user = { id: event.user?.id };
      }
      return event;
    },
    environment: env.VITE_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration({ router: getRouters().router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.httpContextIntegration(),
      //we don't want to submit database queries to Sentry or xhr requests as it could be sensitive data
      Sentry.breadcrumbsIntegration({ fetch: false, xhr: false }),
    ],
    release: [`fe-${siteProfile}`, env.VITE_PACKAGE_VERSION].join("@"),
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [environmentUrl],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
  });
}

app.use(store);
app.use(getRouters().router);
//need to create auth0 plugin after the router configuration or unexpected behavior will occur
app.use(auth0);
app.use(pinia);
app.mount("#app");
