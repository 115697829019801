import { ApolloProvider } from "@vue/apollo-option";
import { ApolloClient, ApolloQueryResult, Observable, TypedDocumentNode } from "@apollo/client";
import { FetchResult } from "@apollo/client/link/core/types";
import { DocumentNode, GraphQLError } from "graphql/index";
import { ErrorPolicy, FetchPolicy, MutationFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import { useNotificationsStore } from "../store/notifications";
import { ApiError, ApiRemoteSchemaError } from "../errors/api";

export class BaseApi {
  protected clientName = "defaultClient";
  protected store = useNotificationsStore();
  protected label: string;
  constructor(
    // TODO: Fix TypeScript configuration, since it thinks "apollo" is an unused variable.
    // eslint-disable-next-line no-unused-vars
    protected readonly apollo: ApolloProvider,
    options?: { label?: string; store?: typeof useNotificationsStore }
  ) {
    this.label = options?.label ?? "Entity";
  }

  getClient(): ApolloClient<any> {
    return this.apollo.clients[this.clientName];
  }

  async query<T, TVariables>(
    query: DocumentNode | TypedDocumentNode<T, TVariables>,
    variables?: TVariables,
    options?: {
      errorPolicy?: ErrorPolicy;
      fetchPolicy?: FetchPolicy;
    }
  ): Promise<T> {
    const result: ApolloQueryResult<T> = await this.getClient().query<T, TVariables>({
      errorPolicy: options?.errorPolicy ?? "all",
      fetchPolicy: options?.fetchPolicy ?? "no-cache",
      query,
      variables,
    });
    this.handleErrors(result);
    return result.data;
  }

  async mutate<TData = any, TVariables = any>(
    mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
    variables?: TVariables,
    options?: {
      errorPolicy: ErrorPolicy;
      fetchPolicy: MutationFetchPolicy;
    }
  ): Promise<TData> {
    const result: FetchResult<TData> = await this.getClient().mutate<TData, TVariables>({
      errorPolicy: options?.errorPolicy ?? "all",
      fetchPolicy: options?.fetchPolicy ?? "no-cache",
      mutation,
      variables,
    });
    this.handleErrors(result);
    return result.data;
  }

  subscribe<T, TVariables>(
    query: DocumentNode | TypedDocumentNode<T, TVariables>,
    variables: TVariables,
    options?: {
      errorPolicy: ErrorPolicy;
      fetchPolicy: MutationFetchPolicy;
    }
  ): Observable<FetchResult<T>> {
    return this.getClient().subscribe<T, TVariables>({
      errorPolicy: options?.errorPolicy ?? "all",
      fetchPolicy: options?.fetchPolicy ?? "no-cache",
      query,
      variables,
    });
  }

  private handleErrors<TData>(result: FetchResult<TData>): void {
    if (result.errors) {
      const firstError: GraphQLError = result.errors[0];
      if (firstError.extensions?.code === "remote-schema-error") {
        const remoteService = this.clientName === "defaultClient" ? "momentum" : this.clientName;
        const message: string =
          firstError.message === "Failed reading: not a valid json value"
            ? `Bad response from remote GraphQL service : ${remoteService}`
            : firstError.message;
        throw new ApiRemoteSchemaError(message);
      }
      throw new ApiError(firstError.message);
    }
  }

  protected postSuccess(message: string): void {
    this.store.setSuccessMessage(message);
  }

  protected handleError(
    error: Error | { message: string; name: string },
    options?: { hideErrorFromUser: boolean }
  ): Promise<void> {
    if (typeof error === "object" && error.name && error.message && !options?.hideErrorFromUser) {
      this.store.setErrorMessage([error.name, error.message].join(" : "));
    }
    throw error;
  }
}
