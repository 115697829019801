<template>
  <section class="w-full max-w-md">
    <button type="button" class="w-full max-w-md" :class="{'cursor-default': notification.severity !== 'error'}" @click="showErrorMessage">
      <div
        class="relative flex flex-wrap justify-between items-center rounded-md shadow-md sm:rounded-md p-4 mb-2 w-full"
        :class="[setBackgroundColor(notification.severity)]"
      > 
        <IconEye v-show="notification.severity === 'error'" class="absolute top-1 right-6 h-4 w-4 text-white" />

        <button type="button" class="absolute top-1 right-1" @click="clearNotification">
          <IconXClose class="h-4 w-4 text-white" />
        </button>
      
        <div class="flex items-center">
          <div class="flex-shrink-0 items-center">
            <!-- Heroicon name: solid/exclamation -->
            <div v-show="notification.severity === 'warning'" class="h-8 w-8 text-white">
              <IconWarning />
            </div>
            <div v-show="notification.severity === 'info'" class="h-8 w-8 text-white">
              <IconSuccess />
            </div>
            <div v-show="notification.severity === 'error'" class="h-8 w-8 text-white">
              <IconError />
            </div>
          </div>
          <div class="ml-3">
            <p class="ml-3 text-left text-sm" :class="[setTextColor(notification.severity)]" v-text="renderMessage(notification)" />
          </div>
        </div>
      </div>
    </button>
    <CModal v-if="state.showErrorMessage" size="medium" :show="state.showErrorMessage" @close="closeModal">
      <label class="mb-2">Error:</label>
      <p class="text-red-600" v-text="notification.message" />
      <template #footer>
        <div class="flex justify-end">
          <CButton type="button" name="secondaryButton" @click="closeModal">
            Close
          </CButton>
        </div>
      </template>
    </CModal>
  </section>
</template>

<script lang="ts">
import { defineComponent, reactive, onMounted, PropType } from "vue";

import { useNotificationsStore } from "../../store/notifications";
import { formatDateTZ, limitString } from "../../utils/common-js";
import IconError from "../icons/IconError.vue";
import IconSuccess from "../icons/IconSuccess.vue";
import IconWarning from "../icons/IconWarning.vue";
import IconXClose from "../icons/IconXClose.vue";
import IconEye from "../icons/IconEye.vue";
import { TNotification } from "../../types/notification";
import { CButton, CModal } from "../library";

export default defineComponent({
  components: {
    CButton,
    CModal,
    IconError,
    IconSuccess,
    IconWarning,
    IconXClose,
    IconEye,
  },
  props: {
    notification: {
      type: Object as PropType<TNotification>,
      required: true,
    },
  },
  setup(props) {
    interface State {
      showErrorMessage: boolean;
      show: boolean;
    }

    const notificationsStore = useNotificationsStore();
    const state: State = reactive({
      showErrorMessage: false,
      show: true,
    });

    onMounted(() => {
      setTimeout(() => {
        clearNotification();
      }, 8000); //8 seconds
    });

    function clearNotification(event?: Event): Promise<void> {
      if (event) {
        event.stopPropagation();
      }
      if (state.showErrorMessage) return null;
      state.show = false;
      notificationsStore.removeNotification(props.notification.id);
    }

    function showErrorMessage(): void {
      if (props.notification.severity === "error") {
        state.showErrorMessage = true;
      }
    }

    function setBackgroundColor(type: TNotification["severity"]): string {
      switch (type) {
        case "error":
          return "bg-red-400";
        case "warning":
          return "bg-amber-400";
        case "info":
          return "bg-mm-colors-secondary";
        default:
          return "bg-gray-400";
      }
    }

    function setTextColor(type: TNotification["severity"]): string {
      return type === "warning" ? "text-black" : "text-white";
    }

    function closeModal(): void {
      state.showErrorMessage = false;
      setTimeout(() => {
        clearNotification();
      }, 100);
    }

    function renderMessage(notification: TNotification): string {
      if (notification.severity === "error") {
        return limitString(notification.message, 200, null, { showMore: true });
      }
      return notification.message;
    }

    return {
      state,
      closeModal,
      showErrorMessage,
      clearNotification,
      formatDateTZ,
      setBackgroundColor,
      setTextColor,
      limitString,
      renderMessage,
    };
  },
});
</script>
