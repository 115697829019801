<template>
  <dialog ref="dialogElement" class="bg-transparent w-full" :class="[renderModalSize()]">
    <Transition name="slide-up">
      <section v-if="show">
        <div class="relative overflow-y-scroll bg-transparent bg-white rounded-lg text-left transition-all w-full py-6 px-10">
          <div>
            <div class="pt-2 flex items-center justify-between">
              <CHeading :title="props.title" />
              <div>
                <slot name="extra-header" />
              </div>
            </div>
            <p class="text-gray-400">
              <slot name="secondary-header" />
            </p>
          </div>
          <div class="py-4">
            <slot />
          </div>
          <div class="pt-4">
            <slot name="footer">
              <div class="flex items-center justify-end">
                <div class="flex items-center">
                  <CButton
                    id="modal-cancel"
                    type="button"
                    name="secondaryButton"
                    class="mr-4"
                    @click="cancelDialog"
                  >
                    <span v-text="buttonOptions?.closeText || 'Cancel'" />
                  </CButton>
                  <CButton
                    id="modal-submit"
                    type="button"
                    name="primaryButton"
                    :loading="buttonOptions?.loadingSubmit"
                    :disabled="buttonOptions?.disableSubmit"
                    @click="submitDialog"
                  >
                    <span v-text="buttonOptions?.submitText || 'Submit'" />
                  </CButton>
                </div>
              </div>
            </slot>
          </div>
        </div>
      </section>
    </Transition>
  </dialog>
</template>

<script lang="ts" setup>
import { ref, onMounted, watch, onUnmounted, PropType } from "vue";
import { TModalSize, TModalButtonOptions } from "./types";
import CButton from "../button/CButton.vue";
import CHeading from "../heading/CHeading.vue";

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
    required: true,
  },
  size: {
    type: String as PropType<TModalSize>,
    default: "large",
  },
  title: {
    type: String,
    default: "",
  },
  buttonOptions: {
    type: Object as PropType<TModalButtonOptions>,
    default: null,
  },
});

const emit = defineEmits(["close", "cancel", "submit"]);
const dialogElement = ref<HTMLDialogElement | null>(null);

onMounted(() => {
  showDialog();
  dialogElement.value.addEventListener("close", closeDialog);
});

onUnmounted(() => {
  document.removeEventListener("close", closeDialog);
});

watch(
  () => props.show,
  () => {
    showDialog();
  }
);

function renderModalSize(): string {
  switch (props.size) {
    case "small":
      return "sm:max-w-md";
    case "medium":
      return "sm:max-w-lg";
    case "large":
      return "sm:max-w-2xl";
    case "x-large":
      return "sm:max-w-3xl";
    case "2x-large":
      return "sm:max-w-4xl";
    case "3x-large":
      return "sm:max-w-5xl";
  }
}

function cancelDialog(): void {
  emit("cancel", true);
}

function closeDialog(): void {
  emit("close", true);
}

function showDialog(): void {
  if (props.show) {
    dialogElement.value?.showModal();
  } else {
    dialogElement.value?.close();
  }
}

function submitDialog(): void {
  emit("submit", true);
}
</script>

<style>
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.1s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
