<template>
  <div class="absolute bottom-[70px] left-[20px]">
    <TransitionGroup name="list" tag="ul">
      <li v-for="notification in storeState.notifications" :key="notification.id" class="w-96">
        <AppNotification :notification="notification" />
      </li>
    </TransitionGroup>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useNotificationsStore } from "../../store/notifications";
import AppNotification from "./AppNotification.vue";

export default defineComponent({
  components: {
    AppNotification,
  },
  setup() {
    const { state: storeState } = useNotificationsStore();

    return {
      storeState,
    };
  },
});
</script>

<style>
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translate(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
  transform: scale(0.8);
  transition: all 1s ease;
  width: 100%;
}
</style>
