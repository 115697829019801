import gql from "graphql-tag";
import { DocumentNode } from "graphql";
import { BaseApi } from "../base";
import { TOffline } from "./types";

export class OfflineApi extends BaseApi {
  protected clientName = "graphcms";
  async get(): Promise<TOffline> {
    type TData = { offlines: TOffline[] };
    const query: DocumentNode = gql`
      query GetOffline {
        offlines {
          isOffline
          message
          environment
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, null>(query);
      const offline = result.offlines[0];
      return offline;
    } catch (error) {
      this.handleError(error, { hideErrorFromUser: true });
    }
  }
}
