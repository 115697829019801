<template>
  <div>
    <NConfigProvider :theme-overrides="themeOverrides">
      <NMessageProvider placement="bottom-right">
        <AppLayout>
          <router-view />
        </AppLayout>
      </NMessageProvider>
    </NConfigProvider>
  </div>
</template>

<script lang="ts">
import { ApolloProvider } from "@vue/apollo-option";
import { defineComponent, watch, ref } from "vue";
import { NConfigProvider, NMessageProvider } from "naive-ui";
import { useStore, Store } from "vuex";
import { useRouter, Router } from "vue-router";
import AppLayout from "./layouts/AppLayout.vue";
import { UserApi } from "./api/user";
import { isAtLeastRoleEditor } from "./utils/roles";
import { User } from "./types/user";
import { FeatureSetApi } from "./api/feature-set/api";
import { OfflineApi } from "./api/offline/api";
import { useFeaturesStore } from "./store/features";
import { environment } from "./utils/import-env-vars";
/**
 * Use this for type hints under js file
 * @type import('naive-ui').GlobalThemeOverrides
 */

export const themeOverrides = {
  Input: {
    border: "1px solid rgb(107 114 128)",
    borderHover: "1px solid rgb(37, 99, 235)",
    borderFocus: "2px solid rgb(37, 99, 235)",
    caretColor: "#000000FF",
    // boxShadowFocus: "0 0 0 2px rgba(28, 122, 196, 0.2)",
  },
  Tabs: {
    tabTextColorActiveBar: "#2080F0FF",
    tabTextColorHoverBar: "#2080F0FF",
    barColor: "#2080F0FF",
  },
  Tooltip: {
    color: "#FFF",
    textColor: "#000",
  },
  // ...
};
export default defineComponent({
  name: "App",
  components: {
    AppLayout,
    NConfigProvider,
    NMessageProvider,
  },
  setup() {
    const store: Store<any> = useStore();
    const router: Router = useRouter();
    const isOffline = ref(false);
    let userApi: UserApi = null;

    watch(
      () => store.state.authUser,
      async (authUser) => {
        if (!authUser?.userId || userApi) {
          return;
        }

        await checkForManualOfflineStatus();
        userApi = new UserApi(store.state.apollo);
        await fetchProfileDetails(authUser.userId, store.state.apollo);
        const featureSetApi = new FeatureSetApi(store.state.apollo);
        const features = await featureSetApi.getAll();
        const featuresStore = useFeaturesStore();
        featuresStore.setAllFeatures(features);
        if (isOffline.value) {
          redirectToOfflinePage();
        }
      },
      {
        immediate: true,
      }
    );

    async function checkForManualOfflineStatus(): Promise<void> {
      const offlineApi = new OfflineApi(store.state.apollo);
      const result = await offlineApi.get();
      if (result.environment === environment) {
        isOffline.value = result.isOffline;
      }
    }

    setInterval(() => {
      if (store.state.authUser) {
        checkForManualOfflineStatus();
      }
      if (isOffline.value) {
        redirectToOfflinePage();
      }
    }, 20_000);

    function redirectToOfflinePage(): void {
      router.push({ name: "offline" });
    }

    async function fetchProfileDetails(userId: number, apollo: ApolloProvider): Promise<void> {
      if (!userId || !apollo) {
        return;
      }
      let user: User = null;
      if (isAtLeastRoleEditor(store.state.authUser)) {
        user = await userApi.get(userId);
        setProfileInStore(user);
      } else {
        user = await userApi.getInfo(userId);
        setProfileInStore(user);
      }
    }

    function setProfileInStore(user: User): void {
      store.dispatch("setProfile", user);
    }

    return {
      themeOverrides,
    };
  },
});
</script>

<style>
body {
  @apply bg-gray-100;
}
</style>
